import { HttpHeaders } from '@angular/common/http';
import { ErrorMessageMap, IDomainMessage } from '@ra-state';
import { Observable } from 'rxjs';

type CommandRequestOptions = {
  body: any;
  headers: HttpHeaders;
  observe: 'response';
};
type HttpMethod = 'POST' | 'DELETE' | 'PUT';
type EventToWaitType = EventPredicate | DomainEventName | DomainEvents;

export class CommandRequest {
  get Method(): HttpMethod {
    return this.method;
  }

  get ApiEndpoint(): string {
    return this.apiEndPoint;
  }

  get RequestOptions(): CommandRequestOptions {
    return {
      body: this.body,
      headers: this.headers,
      observe: 'response',
    };
  }

  get TimeOutInMillies(): number {
    return this.timeoutMillis;
  }

  get errorHandler$(): ((error: unknown) => Observable<any>) | undefined {
    return this.errorHandler;
  }

  get ErrorMessageMap(): ErrorMessageMap {
    return this.errorMsgMap;
  }

  get IsAssistedRequest(): boolean {
    return this.isAssistedRequest;
  }

  public skipWaitingOnResponse(responseStatus: number): boolean {
    return !this.WaitOnSuccessResponsePredicate(responseStatus);
  }

  private apiEndPoint: string;
  private timeoutMillis: number;
  private eventToWait: EventToWaitType;
  private headers: HttpHeaders;
  private body: any;
  private isAssistedRequest: boolean = false;
  private method: HttpMethod = 'POST';
  private errorHandler?: (error: unknown) => Observable<any>;
  private errorMsgMap: ErrorMessageMap = {};
  private WaitOnSuccessResponsePredicate: WaitOnSuccessResponsePredicate;
  private constructor(
    apiEndPoint: string,
    method: HttpMethod,
    eventToWait: EventToWaitType,
    body: any,
    headers: HttpHeaders,
    timeoutMillis: number,
    waitOnSuccessResponsePredicate: WaitOnSuccessResponsePredicate,
  ) {
    this.apiEndPoint = apiEndPoint;
    this.method = method;
    this.eventToWait = eventToWait;
    this.body = body;
    this.headers = headers;
    this.timeoutMillis = timeoutMillis;
    this.WaitOnSuccessResponsePredicate = waitOnSuccessResponsePredicate;
  }

  private static waitOnCreatedResponse(status: number): boolean {
    return status === 201;
  }

  static create(
    apiEndPoint: string,
    method: HttpMethod,
    eventToWait: EventToWaitType,
    options: IDomainUpdateRequestBuilderOptions = {} as IDomainUpdateRequestBuilderOptions,
  ): CommandRequest {
    const defaultOptions = {
      WaitOnSuccessResponsePredicate: CommandRequest.waitOnCreatedResponse,
      body: {},
      headers: new HttpHeaders(),
      timeoutSeconds: 60,
    };
    const targetOptions = Object.assign(defaultOptions, options);
    const timeoutMillis = targetOptions.timeoutSeconds * 1000;
    const waitOnSuccessResponsePredicate = targetOptions.WaitOnSuccessResponsePredicate;

    return new CommandRequest(
      apiEndPoint,
      method,
      eventToWait,
      targetOptions.body,
      targetOptions.headers,
      timeoutMillis,
      waitOnSuccessResponsePredicate,
    );
  }

  asAssistedRequest(): this {
    this.isAssistedRequest = true;
    return this;
  }

  // TO DO: define retuning type
  withBody(body: any): this {
    this.body = body;
    return this;
  }

  withTenantHeader(tenantId: string): this {
    this.headers = this.headers.set('tenantid', tenantId);
    return this;
  }

  withCustomTimer(customtimer: string): this {
    this.headers = this.headers.set('customtimer', customtimer);
    return this;
  }

  withIdempotencyKey(idempotencyKey: string): this {
    this.headers = this.headers.set('idempotencyKey', idempotencyKey);
    return this;
  }

  withTimeout(timeoutInSeconds: number): this {
    this.timeoutMillis = timeoutInSeconds * 1000;
    return this;
  }

  withWaitOn201Created(): this {
    this.WaitOnSuccessResponsePredicate = CommandRequest.waitOnCreatedResponse;
    return this;
  }

  withWaitOn200Ok(): this {
    this.WaitOnSuccessResponsePredicate = (responseStatus: number): boolean => responseStatus === 200;
    return this;
  }

  withWaitOn202Accepted(): this {
    this.WaitOnSuccessResponsePredicate = (responseStatus: number): boolean => responseStatus === 202;
    return this;
  }

  withErrorHandler(errorHandler: (error: unknown) => Observable<any>): this {
    this.errorHandler = errorHandler;
    return this;
  }

  withErrorMessageMap(errorMsgMap: ErrorMessageMap): this {
    this.errorMsgMap = errorMsgMap;
    return this;
  }

  // TO DO: define retuning type
  waitOnResponse(fn: (status: number) => boolean): any {
    this.WaitOnSuccessResponsePredicate = fn;
    return this;
  }

  public eventPredicateSatisfied = (domainMessage: IDomainMessage): boolean => {
    if (!domainMessage) {
      return false;
    }

    if (typeof this.eventToWait === 'string') {
      return domainMessage.type === this.eventToWait;
    } else if (typeof this.eventToWait === 'function') {
      return this.eventToWait(domainMessage);
    }
    return false;
  };

  public notEventPredicateSatisfied = (domainMessage: IDomainMessage): boolean => {
    return !this.eventPredicateSatisfied(domainMessage);
  };
}

export type WaitOnSuccessResponsePredicate = (status: number) => boolean;
export type EventPredicate = (domainMessage: IDomainMessage) => boolean;

export interface IDomainUpdateRequestBuilderOptions {
  body: any;
  headers: HttpHeaders;
  timeoutSeconds: number;
  WaitOnSuccessResponsePredicate: WaitOnSuccessResponsePredicate;
}

export enum UserEvents {
  UserSecondaryEmailsSet = 'UserSecondaryEmailsSet',
  UserRoleAssigned = 'UserRoleAssigned',
  SpUserCreated = 'SpUserCreated',
  UserRoleRevoked = 'UserRoleRevoked'
}

export enum TenantEvent {
  Archived = 'Archived',
  Unarchived = 'Unarchived'
}

export enum CustomerSupportEvents {
  ServiceRequestResolved = 'ServiceRequestResolved',
}

export enum InvitationEvents {
  InvitationCreated = 'InvitationCreated',
}

export enum TrialEvents {
  TrialReserved = 'TrialReserved',
  TrialUnreserved = 'TrialUnreserved',
  CampaignNameChanged = 'CampaignNameChanged',
  CampaignDescriptionChanged = 'CampaignDescriptionChanged',
  CampaignPaused = 'CampaignPaused',
  CampaignResumed = 'CampaignResumed',
  CampaignEnded = 'CampaignEnded',
}

export type DomainEvents = UserEvents | InvitationEvents | CustomerSupportEvents | TrialEvents;

export type DomainEventName =
  | keyof typeof InvitationEvents
  | keyof typeof UserEvents
  | keyof typeof CustomerSupportEvents
  | keyof typeof TrialEvents;
